.fcontainer{
    display:flex;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.fleft-section {
    width: 50%;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 20px;
    border-top-left-radius: 20px;
  }
  .img1{
    margin-right: 15%;
    align-self: flex-end;
  }
  .img2{
    margin-top: 8%;
  }
  .img3{
    margin-top:45px;
    align-self: flex-start;
    margin-left: 13%;
  }
  .fright-section {
    width: 50%;
    display: flex;
    padding-left: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: #ECEFFB;
    border-radius: 12px;
  }
  .img4{
    align-self: flex-end;
    margin-right: 20px;
  }
  .img5{
    align-self: flex-start;
    margin-left: 60px;
  }
  .fcenter{
    margin-top: 40px;
    margin-right: 12%;
  }
  .fcenter h1{
    color: #37304A;
    font-size: 1.7rem;
  }
  .fcenter p{
    color:#374572;
    margin-left: 6px;
    font-size: 1.3rem;
  }
  .form-g {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Add spacing between input containers */
}
.input-cont {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-right: 10%;
}

.input-cont input {
    align-self: flex-start;
    padding: 16px;
    font-size: 1rem;
    border-radius: 10px;
    border: 1px solid white;
    width: 130%;
    transition: border-color 0.3s ease;
    margin-right: 10%;
}
  .ftextbox{
    margin-top: 23%;
  }


.fregister-btn{
    align-self: center;
    margin-top: 40px;
    width: 120%;
    font-size: 18px;
    background-color: #B2A0FB; /* Green */
    border: 2px solid white;
    color: white;
    padding: 15px 32px;
    border-radius: 12px;
}
.fp{
    margin-top: 12%;
    font-size: 16px;
    color: #808081;
    align-self: center;
    margin-right: 8%;
    margin-left: 5%;
}
.fright-section .img6{
    align-self: flex-end;
    margin-right: 40px;
}
.img7{
    align-self: flex-start;
    margin-left: 50px;
    margin-top: 32px;
    position: relative;
}

@media (max-width:780px) {

.fcontainer{
    display:flex;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    height: auto;
}
.fleft-section {
    width: 100%;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 20px;
    border-top-left-radius: 20px;
  }
  .img1{
    align-self: flex-end;
  }
  .img2{
    margin-top: 8%;
  }
  .img3{
    margin-top:45px;
    align-self: flex-start;
    margin-left: 13%;
  }
  .fright-section {
    width: 100%;
    display: flex;
    padding-left: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: #ECEFFB;
    border-radius: 12px;
  }
  .img4{
    align-self: flex-end;
    margin-right: 20px;
    width: 20%;
  }
  .img5{
    align-self: flex-start;
    margin-left: 20px;
    width: 22%;
    margin-top: 10px;

  }
  .fcenter{
    margin-top: 40px;
    align-self: center;

  }
  .fcenter h1{
    color: #37304A;
    font-size: 1.4rem;
    margin-left: 8px;
  }
  .fcenter p{
    color:#374572;
    font-size: 1.4rem;
  }
  .form-g {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Add spacing between input containers */
}
.input-cont {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-right: 10%;
}

.input-cont input {
    align-self: flex-start;
    padding: 16px;
    font-size: 1rem;
    border-radius: 10px;
    border: 1px solid white;
    width: 110%;
    transition: border-color 0.3s ease;
    margin-right: 10%;
}

  .ftextbox{
    margin-top: 25%;
  }


.fregister-btn{
    align-self: center;
    margin-top: 40px;
    width: 100%;
    font-size: 16px;
    border: 3px solid white;
}
.fp{
    margin-top: 15%;
    font-size: 14px;
    color: #808081;
    align-self: center;
    margin-right: 8%;
    margin-left: 5%;
}
.fright-section .img6{
    align-self: flex-end;
    margin-right: 40px;
    width: 10%;
}
.img7{
    align-self: flex-start;
    margin-left: 50px;
    margin-top: 15px;
    width: 20%;
}
}