.signup{
    display:flex;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.left-sectio {
    width: 50%;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    border-top-left-radius: 20px;
    column-gap: 0px;
  }

  .right-sectio {
    width: 45%;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ECEFFB;
    border-radius: 12px;
  }
  .whiterr{
    position: absolute;
    left: 80%;
    top:0px;
    height: 150px;
  }
  .p{
    align-items: center;
    padding-top: 12%;
    display: flex;
    flex-direction: column;
  }
  .p1{
    font-size: 1.7rem;
    margin-bottom: 10px;
    color: white;
  }
  .p2{
    font-size: 22px;
    font-weight: 500;
    color: white;
    margin-bottom: 40px;
    word-spacing: 6px;
  }
  .signupim{
    margin-bottom: 30px;
  }
  .social{
    display: flex;
    justify-content: center; /* Space the images evenly */
    align-items: center; /* Center the images vertically */
    border-radius: 8px;
    margin-top: 20px;
  }
  .social img {
    height: 100px;
    margin: 0 10px;
    border-radius: 50%;
    border: none;
    background-size: cover;
    cursor: pointer;
  }

 a{
    text-decoration: none;
    color: #1877F2;
}
.circular-square-rig{
    position: absolute;
    top: 0%;right: 20px;
  }
   .circular-square-lef{
    position: absolute;
    bottom: 0px;
    left:60%;
   }
    .dot-le{
        align-self: flex-start;
        margin-top: 30px;
        margin-left: 20px;
    } .dot-rig {
        position:absolute;
        align-self: flex-end;
    bottom: 3%;
  right: 3%; /* Hide decorative elements on mobile for simplicity */
  }


  .form-text {
    margin-top: 30px;
    display: flex;
    align-self: flex-start;
    justify-content: center;
    margin-bottom: 20px;
}

.form-text h2 {
    color: #374572;
}

.form-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
    gap: 20px; /* Add spacing between input containers */
}

.form-grou {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;
    flex-direction: column;
}

.input-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.input-container input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 10px;
    border: 1px solid white;
    width: 100%;
    transition: border-color 0.3s ease;
}

.error-text {
    color: red;
    font-size: 0.7rem;
    margin-top: 5px;
    padding-left: 10px;
}

.error-input {
    border-color: red !important; /* Red border for inputs with errors */
}

.error {
    border-color: red;
}

.register-btn {
    align-self: center;
    width: 55%;
    padding: 15px;
    font-size: 1rem;
    color: white;
    background-color: #b2a0fb;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px;
    border: 3px solid white;
}

  
  .register-btn:hover {
    background-color: #a18ffb;
  }

  @media (max-width: 780px){
    .signup{
        flex-direction: column;
        height: auto;
    }
    .left-sectio {
        width: 100%;
        height: auto;
        position: relative;
      }
      .right-sectio {
        width: 100%;
        height: auto;
        flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: #ECEFFB;
    border-radius: 12px;
      }
      .social{
        display: flex;
        justify-content: center; /* Space the images evenly */
        align-items: center; /* Center the images vertically */
        border-radius: 8px;
        margin-top: 20px;
      }
      .social img {
        height: 80px;
        margin: 0 5px;
        border-radius: 50%;
        border: none;
        background-size: cover;
        cursor: pointer;
      }
      .form-group input {
        width: 100%;
        padding: 10px;
        font-size: 0.9rem;
        border-radius: 10px;
        border: 1px solid white;
    
      }
      .form-grou input{
        width: 100%;
        padding: 10px;
        font-size: 0.9rem;
        border-radius: 10px;
        border: 1px solid white;
      }
   .right-sectio .circular-square-rig{
        position: relative;
        left: 55%;
        width: 80px;
        align-self: flex-end;
    }
    .dot-le{
        width: 20%;
    }
    .dot-rig{
       height: 4.5%;
        bottom: 40px;
    }
    .circular-square-lef{
        left:8%
    }
    
  }