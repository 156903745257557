.app {
  position:absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f8f9fa;
}

.container {
  display:flex;
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
}

.left-section {
  background: linear-gradient(300deg,#DA97FC,#DA97FC, #9397EF);
  display:flex;
  width: 52%;
  justify-content: center;
  padding: 20px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}



.right-section {
  padding: 40px;
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ECEFFB;
  border-radius: 12px;
  
}

.right-section h1 {
  margin: 0;
  font-size: 3.0em;
  color: #37304A;
}
.right-section h2 {
  margin: 20px;
  font-size: 2em;
  color: #374572;
}


.right-section p {
  margin: 20px 0;
  font-size: 1rem;
  color: #334888;
}

.images{
text-align: center;
}
.image1{
  height: auto;
  width: auto;
}

.register{
  padding: 24px;
  padding-right: 30px;
  background-color: #B2A0FB;
  color: #fff;
  font-weight:bold;
  border-radius: 10px;
}

.butt{
  background-color: white;
  display: flex;
  border-radius: 14px;
  border-radius: 14px;
  padding: 1px;
}

.butt button{
  flex: 1;
  padding: 20px 40px;
  border: 1px solid white; /* Added border to make the border visible */
  cursor: pointer;
  border-radius: 10px; /* Ensures consistent border-radius */
  font-weight: bold; 

}

.butt .signin-bt {
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: rgba(248, 243, 243, 0.944);
  color: black;
  font-weight:bold;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.register:hover {
  background-color: #beb0f2;
}

.signin-bt:hover {
  background-color: #FFFFFF;
}

.white-right{
  position:absolute;
  top: 0px;
  left: 40%;
}

.white-left{
  position: absolute;
  bottom: 0px;
  left: 5%;
}

.circular-square-right{
position: absolute;
top: 0px;right: 20px;}

.circular-square-left{
position: absolute;
bottom: 0px;
left:55%;
}
.dot-left{
  position:absolute;
  top: 70px;
  right: 38%;
 
}
.dot-right{
  position: absolute;
  bottom: 10%;
  right: 3%;
}

h1{
position: relative;
}

h2{
  position: relative;
}

p{
  position: relative;
}

@media  (max-width: 761px) {
  body {
      background-color: white;
  }
  .image1{
    width: 150px;
    height: 70px;
  }
  .image2{
    width: 250px;
    height: 500px;
  }
  .white-left{
    height: 40px;
    width: 45px;
  }
  .white-right{
    height: 20px;
    width: 45px;
  }
  .dot-left{
    right: 26%;
    height: auto;
    width: 100px;
  }
  .dot-right{
   height: 100px;
   right: 5%;
  }
  .circular-square-right{
    width: 100px;
  }
  .circular-square-left{
    width: 100px;
    right: 20%;
  }
}

@media (max-width: 780px) {
  body{
    background-color: white;
  }

  .container {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    position: absolute;
    height: auto;
  }
  
  .left-section {
    flex:1;
    background: linear-gradient(300deg,#DA97FC,#DA97FC, #9397EF);
    display:flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    border-top-left-radius: 20px;
    width: auto;
  }
  .right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: auto;
    position: relative;
    justify-content: center;    
    background-color: #ECEFFB;
    border-radius: 20px;
    padding: 20px;
    /* or use min-height */
    min-height: 480px;

  }
  .right-section h1 {
    padding-top: 30%;
    font-size: 3.0em;
    color: #37304A;
  }
  .right-section h2 {
    font-size: 2em;
    color: #374572;
  }
  
  .right-section p {
    font-size: 1rem;
    color: #334888;
    margin-right: 10px;
    margin-left: 10px;
    align-self: start;
  }
  .register{
    background-color: #B2A0FB;
    color: #fff;
    font-weight:bold;
  }
  .butt {
    display: flex;
    margin: 0px;
    border-radius: 14px;
    background-color: transparent; /* Explicitly set background to transparent */
  }

  .butt button {
    flex: 1;
    padding: 20px 40px;
    cursor: pointer;
    border-radius: 10px; /* Ensures consistent border-radius */
    font-weight: bold; 
    gap: 4px;
  }

  .butt .signin-btn {
    padding: 0px;
    background-color: rgba(248, 243, 243, 0.944);
    color: black;
  }

  .register:hover {
    background-color: #B2A0FB;
  }

  .signin-btn:hover {
    background-color: #FFFFFF;
  }
  
  
  h1{
    position: relative;
    }
    
    h2{
      position: relative;
    }
    
    p{
      position: relative;
    }
    
  .image1{
    width: 60%;
    height: 30%;

  }
  .image2{
    width: 120%;
    height: 120%;
  }
  .white-left{
    width: 45px;
    left: 15%;
    position: absolute;
  }
  .white-right{
    height: 20px;
    width: 45px;
    left: 80%;
    position: absolute;
  }
  .dot-left{
    left: 7%;
    height: auto;
    width: 80px;
  }
  .dot-right{
    position: relative;
   height: 80px;
   left: 40%;
   margin-top: 50px;
  }
  .circular-square-right{
    width: 9vh;
  }
  .circular-square-left{
    width: 9vh;
    left: 10%;
    margin-top: 40px;
  }
  
}
