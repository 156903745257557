.logincon{
    display:flex;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 100%;
      }

.left-sectio {
    flex:1;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    border-top-left-radius: 20px;
  }

  .right-section {
    padding: 40px;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ECEFFB;
    border-radius: 12px;
  }
 
.whiterr{
  position: absolute;
  left: 80%;
  top:0px;
  height: 150px;
}

.crecs{
  padding-top: 30%;
  display: grid;
  grid: 0%;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.crecsflex{
  margin:70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px; /* Space between imageSerecs, subtitle, and description */
}

.imageSerecs{
  width: 65%;
  padding-top: 50px;
  position: relative;
}

.subtitle{
  color: white;
  font-size: 35px;
  margin: 0px;
}
.description{
  color: white;
  font-size: 20px;
  padding-top: 0px;
  margin: 4px;
}

.imageDragon{
  width:100%;
}

.whiterl {
  position: absolute;
  left: 10%;
  bottom: 0px;
  height: 150px; /* Ensure the height is consistent with the inline style */
}

.right-section .ptag{
  color: #737081;
  font-size: 24px;
}

input.textbox{
  width: 47%; /* Makes the input take the full width of its container */
  padding: 12px 20px; /* Adds padding inside the input */
  margin: 8px 0; /* Adds space outside the input */
  box-sizing: border-box; /* Ensures padding and border are included in the element's width and height */
  border: 2px solid white; /* Adds a border with custom color */
  border-radius: 10px; /* Rounds the corners of the input */
  background-color: white; /* Sets the background color of the input */
  font-size: 16px; /* Sets the font size */
  color: grey; /* Sets the text color */
  outline: none; /* Removes the default blue outline when the input is focused */
  transition: all 0.3s ease; 
}
.form-gro {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-direction: column;
}

.input-containe {
  /* Flex: 1 can be removed if you don't want the container to expand horizontally */
  display: flex;
  flex-direction: column;
}

/* Choose one of the following options for input width: */
.input-containe input {
  /* Option 1: Fixed Width */
  width: 370px; /* Adjust as needed */
  padding: 10px;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid white;
  transition: border-color 0.3s ease;

  /* Option 2: Flexbox with Max Width */
  /* flex: 1; */ /* Enable if using flexbox approach */
  /* max-width: 300px; */ /* Set a maximum width */
}

.error-text {
  color: red;
  font-size: 0.5rem;
  padding-left: 10px;
}

.error-inpu {
  border-color: red !important; /* Red border for inputs with errors */
}

.error {
  border-color: red;
}

.forgetPassword {
  display: flex;
  align-items: center; /* Aligns items vertically centered */
  justify-content: space-between; /* Adds space between the two elements */
  width: 45%; /* Ensure the container takes full width */
}

.RememberMe {

  display: flex;
  align-items: center; 
  padding-top: 10px;
  /* Vertically center the checkbox and text */
}

.custom-checkbox {
  margin-right: 6px; /* Space between checkbox and label */
  width: 18px; /* Adjust width of the checkbox */
  height: 18px; /* Adjust height of the checkbox */
  border-radius: 4px;
   /* Optional: add border radius */
}

.forgot-password-link {
  color:#2A77DB;
  text-decoration: none;
  margin-left: auto;
  padding-top: 10px;
  font-size: 15px;
  /* Pushes the link to the right side */
}
.button{
  padding-top: 20px;
  width: 20%;
}

.right-section .lbutton{
  padding-top: 20px;
  width: 45%;
  background-color: #B2A0FB; /* Green */
  border: 2px solid white;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
  box-shadow: white;

}
.social{
  display: flex;
  justify-content: space-between; /* Space the images evenly */
  align-items: center; /* Center the images vertically */
  width: 50%; /* Set the width of the container */
  padding: 10px 10px; /* Padding inside the container */
  border-radius: 8px;
}
.continue{
  display: flex;
  justify-content: space-around; /* Space the images evenly */
  align-items: center; /* Center the images vertically */
  width: 50%; /* Set the width of the container */
  padding: 10px 10px; /* Padding inside the container */
  border-radius: 8px;
}

.line {
  width: 20%; /* Adjust the width as needed */
  height: 1.5px; /* 1px height */
  background-color: grey; /* Black color */
}
.circular-square-righ{
  position: absolute;
  top: 0px;right: 20px;
}
 .circular-square-lef{
  position: absolute;
  bottom: 0px;
  left:55%;
 }
  .dot-lef{
    position:absolute;
    top: 70px;
    right: 38%;
  } .dot-righ {
    position: absolute;
  bottom: 10%;
right: 3%; /* Hide decorative elements on mobile for simplicity */
}

@media (max-width:960px) {

  .logincon{
    display:flex;
    flex-direction: column;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    height: auto;
}

.left-sectio {
    flex:1;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    border-top-left-radius: 20px;
  }

  .right-section {
    padding: 40px;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ECEFFB;
    border-radius: 12px;
  }
 
.whiterr{
  position: absolute;
  left: 80%;
  top:0px;
  height: 120px;
}

.crecs{
  padding-top: 30%;
  display: grid;
  grid: 0%;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.crecsflex{
  margin:30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px; /* Space between imageSerecs, subtitle, and description */
}

.imageSerecs{
  width: 60%;
  padding-top: 50px;
  position: relative;
}

.subtitle{
  color: white;
  font-size: 18px;
  margin: 0px;
}
.description{
  color: white;
  font-size: 12px;
  padding-top: 0px;
  margin: 4px;
}

.imageDragon{
  width:100%;
}

.whiterl {
  position: absolute;
  left: 10%;
  bottom: 0px;
  height: 120px; /* Ensure the height is consistent with the inline style */
}
.right-section h2{
  margin-top: 70px;
}
.right-section .ptag {
  font-size: 18px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

input.textbox {
  width: 100%; /* Full width on mobile */
  margin: 8px 8px;
}

.forgetPassword {
  width: 90%;
  display:flex;
  align-items: flex-start;
  justify-content: space-between; /* Adds space between the two elements */
  /* Align elements to the left */
}

.RememberMe {
  display: flex;
  padding-top: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.forgot-password-link {
  margin-left: 0;
  font-size: 14px;
  padding-top: 5px;
  margin-top: 15px;
  color:#2A77DB;
}

button {
  width: 100%;
  margin-top: 10px; /* Add margin to separate from other elements */
}

.social {
  width: 100%;
  display: flex; /* Stack icons vertically */
  gap: 10px; /* Add spacing between icons */
}

.continue {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.line {
  width: 30%;
}
.circular-square-righ{
  width: 9vh;
  right: 10%;
} .circular-square-lef{
  width: 9vh;
  left: 10%;
} .dot-lef{
  top: 5%;
  width: 16%;
  left: 7%;

} .dot-righ {
  height: 80px;
  bottom: 20px;
  right: 4%; /* Hide decorative elements on mobile for simplicity */
}
}
@media (max-width:500px) {

  .logincon{
    display:flex;
    flex-direction: column;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    height: auto;
}

.left-sectio {
    flex:1;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    position: relative;
    padding: 20px;
    border-radius: 20px;
    border-top-left-radius: 20px;
  }

  .right-section {
    padding: 40px;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ECEFFB;
    border-radius: 12px;
  }
 
.whiterr{
  position: absolute;
  left: 80%;
  top:0px;
  height: 120px;
}

.crecs{
  padding-top: 30%;
  display: grid;
  grid: 0%;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.crecsflex{
  margin:30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px; /* Space between imageSerecs, subtitle, and description */
}

.imageSerecs{
  width: 60%;
  padding-top: 50px;
  position: relative;
}

.subtitle{
  color: white;
  font-size: 18px;
  margin: 0px;
}
.description{
  color: white;
  font-size: 12px;
  padding-top: 0px;
  margin: 4px;
}

.imageDragon{
  width:100%;
}

.whiterl {
  position: absolute;
  left: 10%;
  bottom: 0px;
  height: 120px; /* Ensure the height is consistent with the inline style */
}
.right-section h2{
  margin-top: 70px;
}
.right-section .ptag {
  font-size: 18px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

input.textbox {
  width: 100%; /* Full width on mobile */
  margin: 8px 8px;
}

.forgetPassword {
  width: 90%;
  display:flex;
  align-items: flex-start;
  justify-content: space-between; /* Adds space between the two elements */
  /* Align elements to the left */
}

.RememberMe {
  display: flex;
  padding-top: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.forgot-password-link {
  margin-left: 0;
  font-size: 14px;
  padding-top: 5px;
  margin-top: 15px;
  color:#2A77DB;
}

button {
  width: 80%;
  margin-top: 10px; /* Add margin to separate from other elements */
}

.social {
  width: 100%;
  display: flex; /* Stack icons vertically */
  gap: 10px; /* Add spacing between icons */
}

.continue {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.line {
  width: 30%;
}
.circular-square-righ{
  width: 9vh;
  right: 10%;
} .circular-square-lef{
  width: 9vh;
  left: 10%;
} .dot-lef{
  top: 5%;
  width: 16%;
  left: 7%;

} .dot-righ {
  height: 80px;
  bottom: 20px;
  right: 4%; /* Hide decorative elements on mobile for simplicity */
}
}