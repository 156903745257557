.hcontainer{
    display:flex;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.hleft-section{
    width: 50%;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 20px;
    border-top-left-radius: 20px;
}
.hright-section {
    width: 50%;
    display: flex;
    padding-left: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: #ECEFFB;
    border-radius: 12px;
    overflow:visible;
  }
  .img6{
    align-self: flex-end;
    margin-right: 30px;
    bottom: 0;
  }
  .hcenter{
    margin-top: 3%;
  }
  .hcenter table{
    width: 100%;
  }
  .hcenter th ,td{
    border: 1.5px solid blue;
    padding: 10px;
  }
  .hlbutton{
    margin-left: 17%;
    margin-top: 20px;
    border: 2px solid white;
    width: 60%;
    padding-top: 20px;
    background-color: #B2A0FB; /* Green */
    color: white;
    padding: 15px 32px;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;  
  }
  .hlbutton:hover{
    background-color: #DA97FC;
  }

  @media (max-width:780px) {
    .hcontainer{
        display:flex;
        flex-direction: column;
        background: #f5f5f5;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        overflow: hidden;
        width: 100%;
        height: auto;
    }
    
    .hleft-section{
        width: 100%;
        background: linear-gradient(300deg,#DA97FC,#9397EF);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        border-radius: 20px;
        border-top-left-radius: 20px;
    }
    .hright-section {
        width: 100%;
        display: flex;
        padding-left: 10px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        background-color: #ECEFFB;
        border-radius: 12px;
        overflow:visible;
      }
      .img6{
        align-self: flex-end;
        margin-right: 30px;
        bottom: 0;
      }
      .hcenter{
        margin-top: 3%;
      }
      .hcenter table{
        width: 85%;
      }
      .hcenter th ,td{
        border: 1.5px solid blue;
        padding: 6px;
      }
      .hlbutton{
        margin-left: 6cap;
        margin-top: 20px;
        border: 2px solid white;
        width: 60%;
        padding-top: 20px;
        background-color: #B2A0FB; /* Green */
        color: white;
        padding: 15px 32px;
        display: inline-block;
        font-size: 16px;
        border-radius: 12px;  
      }
      .hlbutton:hover{
        background-color: #DA97FC;
      }
  }