body {
  width: 100%;
  height: 100%;
  position: absolute;
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
}

* {
  box-sizing: border-box;
}
