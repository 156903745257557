.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(245, 244, 244, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #B1A0FB; /* Light purple background */
    padding: 20px;
    border-radius: 10px;
    color: white;
    width: 400px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 20x;
}

.modal-content p{
    font-size: 18px;
    color: white;
}

.modal-close-button {
    background-color: white; /* Slightly darker purple for the button */
    color: black;
    border: none;
    padding: 10px 30px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
    width: 60%;
    transition: background-color 0.3s;
    font-size: 16px;
}

.modal-close-button:hover {
    background-color: #966ef5; /* Darker on hover */
}
.circleImg{
height: 8%;
}

.circleImg2{
    margin-top: 20px;
}
.divide{
    display: flex;
    justify-content:flex-start;
    gap: 50px;
}
.divide h2{
    margin-top: 40px;
    color: white;
    font-size: 1.5rem;
}
.divide2{
    display: flex;
    justify-content:center;
    gap: 30px;
    margin-left: 60px;
}

@media (max-width:480px){
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(245, 244, 244, 0.5); /* Dark overlay */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    
    .modal-content {
        background-color: #B1A0FB; /* Light purple background */
        padding: 10px;
        border-radius: 10px;
        color: white;
        width: 350px;
        text-align: center;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        font-size: 20x;
    }
    
    .modal-content p{
        font-size: 15px;
        color: white;
    }
    
    .modal-close-button {
        background-color: white; /* Slightly darker purple for the button */
        color: black;
        border: none;
        width: 40%;
        padding: 10px 10px;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 10px;
        transition: background-color 0.3s;
    }
    
    .modal-close-button:hover {
        background-color: #966ef5; /* Darker on hover */
    }
    .circleImg{
    height: 3%;
    }
    
    .circleImg2{
        margin-top: 20px;
    }
    .divide{
        display: flex;
        justify-content:flex-start;
        gap: 30px;
    }
    .divide h2{
        margin-top: 40px;
        color: white;
        font-size: 1.5rem;
    }
    .divide2{
        display: flex;
        justify-content:center;
        gap: 30px;
        margin-left: 60px;
    }
}