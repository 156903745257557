.ocontainer{
    display:flex;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.oleft-section {
    width: 50%;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 20px;
    border-top-left-radius: 20px;
  }
  .oright-section {
    width: 50%;
    display: flex;
    padding-left: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: #ECEFFB;
    border-radius: 12px;
    overflow:visible;
  }
  .img6{
    align-self: flex-end;
    margin-right: 30px;

  }
  .ocenter{
    flex:1;
    margin-top: 12%;
    align-self: flex-start;
    margin-left: 20%;
  }
  .ocenter h2{
    color: #37304A;
    font-size: 1.7rem;
  }
  .ocenter p{
    color: #374572;
    font-size: 1.3rem;
  }
.textboxes input{
    border-radius: 12px;
    padding: 18px;
    width: 9%;
    transition: border-color 0.3s ease;
    border: 1px solid black;
    font-size: 1rem;
}
.textboxes{
    margin-top: 8vh;
    display: flex;
    gap:10px;
    margin-left: 20px;
}
.obutton{
    margin-top: 30px;
    margin-left: 20px;
    border: 2px solid white;
    width: 60%;
    padding-top: 20px;
    background-color: #B2A0FB; /* Green */
    color: white;
    padding: 15px 32px;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;  
}
.down{
    align-self: flex-end;
    margin-left: 20%;
    margin-top: 40px;
}
.obutton1{
    text-decoration: none;
    background-color: none;
    width: 0;
    cursor: pointer;
    border:none;
    background: none;
    color: #CD0304;
}

@media (max-width:780px){
  .ocontainer{
    display:flex;
    flex-direction: column;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: auto;
}

.oleft-section {
    width: 100%;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 20px;
    border-top-left-radius: 20px;
  }
  .oright-section {
    width: 100%;
    display: flex;
    padding-left: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: #ECEFFB;
    border-radius: 12px;
    overflow:visible;
  }
  .img4{
    width: 15%;
  }
.img5{
  width:18%
}
  .img6{
    align-self: flex-end;
    margin-right: 30px;
  }
  .img7{
    width: 15%;

  }
  .ocenter{
    flex:1;
    margin-top: 12%;
    align-self: flex-start;
    margin-left: 25%;
  }
  .ocenter h2{
    color: #37304A;
    font-size: 1.7rem;
    margin-left: 10%;  
  }
  .ocenter p{
    color: #374572;
    font-size: 1.1rem;
    margin-left: 5%;  

  }
.textboxes input{
    border-radius: 12px;
    padding: 18px;
    width: 9%;
    transition: border-color 0.3s ease;
    border: 1px solid black;
    font-size: 1rem;
}
.textboxes{
    margin-top: 8vh;
    display: flex;
    gap:10px;
    margin-left: 20px;
}
.obutton{
    margin-top: 30px;
    margin-left: 20px;
    border: 2px solid white;
    width: 60%;
    padding-top: 20px;
    background-color: #B2A0FB; /* Green */
    color: white;
    padding: 15px 32px;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;  
}
.down{
    align-self: flex-end;
    margin-left: 20%;
    margin-top: 40px;
}
.obutton1{
    text-decoration: none;
    background-color: none;
    width: 0;
    cursor: pointer;
    border:none;
    background: none;
    color: #CD0304;
}
}

@media (max-width:480px){
  .ocontainer{
    display:flex;
    flex-direction: column;
    background: #f5f5f5;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    height: auto;
}

.oleft-section {
    width: 100%;
    background: linear-gradient(300deg,#DA97FC,#9397EF);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: 20px;
    border-top-left-radius: 20px;
  }
  .oright-section {
    width: 100%;
    display: flex;
    padding-left: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background-color: #ECEFFB;
    border-radius: 12px;
    overflow:visible;
  }
  .img4{
    width: 15%;
  }
.img5{
  width:18%
}
  .img6{
    align-self: flex-end;
    margin-right: 30px;
    width: 10%;
  }
  .img7{
    width: 15%;

  }
  .ocenter{
    flex:1;
    margin-top: 12%;
    align-self: flex-start;
    margin-left: 10%;
  }
  .ocenter h2{
    color: #37304A;
    font-size: 1.7rem;
    margin: 0;
  }
  .ocenter p{
    color: #374572;
    font-size: 1.1rem;
    margin-left: 0;
  }
.textboxes input{
    border-radius: 12px;
    padding: 16px;
    width: 45px;
    transition: border-color 0.3s ease;
    border: 1px solid black;
    font-size: 1rem;
    margin: 0px;
}
.textboxes{
    margin-top: 8vh;
    display: flex;
    gap:10px;
}
.obutton{
    margin-top: 30px;
    border: 2px solid white;
    width: 95%;
    padding-top: 20px;
    background-color: #B2A0FB; /* Green */
    color: white;
    padding: 15px 32px;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;  
}
.down{
    align-self: flex-end;
    margin-left: 25%;
    margin-top: 40px;
}
.obutton1{
    text-decoration: none;
    background-color: none;
    width: 0;
    cursor: pointer;
    border:none;
    background: none;
    color: #CD0304;
}
}